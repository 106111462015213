<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Code Promo",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Code Promo",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Code Promo",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        code: "",
        reduction: 0,
        dateLimite: new Date().toLocaleDateString(),
        birthday: false,
        moyensTransport: [],
        typesDistance: [],
        experience: [],
        usageLimit:1,
      },
      codePromoFormModif: {
        id: 0,
        code: "",
        reduction: 0,
        dateLimite: "",
        birthday: false,
        moyensTransport: "",
        typesDistance: "",
        experience: "",
      },
      codeReduction:{
        id:null,
        code:"",
        reduction:0,
        usageLimit:0
      },
      codePromoRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      totalRowsR: 1,
      currentPageR: 1,
      perPageR: 100,
      pageOptionsR: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "reduction", sortable: true },
        { key: "code", sortable: true, label: "Code" },
        { key: "dateLimite", sortable: true, label: "Limite" },
        { key: "usageLimit", sortable: true, label: "Limite d'usage" },
        { key: "moyensTransport", sortable: true, label: "Transport" },
        { key: "typesDistance", sortable: true, label: "Distance" },
        { key: "experience", sortable: true },
        { key: "user", sortable: true, label: "Annif de" },
        { key: "birthday", sortable: true, label: "Annif" },
        { key: "admin", sortable: true, label: "Publié par" },
        { key: "dateCreation", sortable: true },
        { key: "action" },
      ],
      fieldsR: [
        // { key: "reduction", sortable: true },
        { key: "code", sortable: true, label: "Code" },
        { key: "reduction", sortable: true, label: "Réduction" },
        { key: "usageLimit", sortable: true, label: "Limite d'usage" },
        
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
      newDataPromo: [],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataWallet: [],
      recherche: "",
      showMessage: false,
      reductionTable: [],
      editRModal: false,
      keyToFind:"",
      foundReduction: [],
    };
  },
  validations: {},

  mounted() {
    this.init();
    this.getReduction();
  },
  methods: {

    async getReduction(){
      this.reductionTable = [];
      const response = await apiRequest("GET","codepromo/list-param",);
      console.log('response found',response );
      
      if (response && response.status == 200 && response.data) {

        response.data.forEach(el => {
          this.reductionTable.push(el)
          
        });
        
        
      } else {

        this.$toast.error('Collecte des codes de réduction échouée')
        
      }

    },

    async findReduction(){
      this.foundReduction = [];

      if(this.keyToFind != ''){

      this.foundReduction = this.reductionTable.filter(el => el.code.toLowerCase().includes(this.keyToFind.toLowerCase()));
      }else{
        this.getReduction();
      }
    },

    
    async init() {
      const listCodePromo = await apiRequest(
        "GET",
        "codepromo/find-admin",
      );

      //console.log("listCodePromo", listCodePromo)

      if (listCodePromo && listCodePromo.status == 200) {
        const codePromoFormatTable = listCodePromo.data.promo.map((promo) => {
          return {
            id: promo.id,
            code: promo.code,
            reduction: promo.reduction + " %",
            dateCreation: new Date(promo.dateCreation).toLocaleString("fr-FR"),
            dateLimite: new Date(promo.dateLimite).toLocaleString("fr-FR"),
            birthday: promo.birthday ? "Oui" : "Non",
            actif: promo.actif,
            idAdmin: promo.idAdmin,
            moyensTransport: promo.moyensTransport,
            typesDistance: promo.typesDistance,
            experience: promo.experience,
            admin: promo.admin.firstname + " " + promo.admin.lastname,
            user: promo.birthday ? promo.user.firstname + " " + promo.user.lastname : "",
          };
        }).sort((a, b) => { b.id - a.id });
        this.codePromo = codePromoFormatTable;
        this.totalValue = this.codePromo.length
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.codePromoFormModif.titre = row.titre;
      this.codePromoFormModif.url_codePromo = row.url;
      this.codePromoFormModif.soustitre = row.soustitre;
      this.codePromoFormModif.id = row.id;
      this.codePromoFormModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.codePromoRowInfo = row;
    },
    activerpromo(row) {
      this.showactive = true;
      this.codePromoRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {

      this.submitted = true;

      this.$v.$touch();

    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },
    handleRedEdit(item){
      this.codeReduction.id = item.id;
      this.codeReduction.reduction = item.reduction;
      this.codeReduction.code = item.code;
      this.codeReduction.usageLimit = item.usageLimit;


      this.editRModal = true
    },
    async submitcodePromoForm() {
      if (
        this.codePromoForm.reduction != 0 &&
        this.codePromoForm.dateLimite != "" &&
        this.codePromoForm.moyensTransport.length > 0 &&
        this.codePromoForm.typesDistance.length > 0 &&
        this.codePromoForm.experience.length > 0
      ) {
        var moyensT = "";
        var typesD = "";
        var exp = "";

        this.codePromoForm.moyensTransport.forEach((element) => {
          moyensT += element + ",";
        });

        this.codePromoForm.typesDistance.forEach((element) => {
          typesD += element + ",";
        });

        this.codePromoForm.experience.forEach((element) => {
          exp += element + ",";
        });


        const returncodePromo = await apiRequest(
          "POST",
          "codepromo",
          {
            code: this.codePromoForm.code,
            reduction: this.codePromoForm.reduction,
            dateLimite: this.codePromoForm.dateLimite,
            birthday: false,
            moyensTransport: moyensT,
            typesDistance: typesD,
            experience: exp,
            usageLimit:this.codePromoForm.usageLimit
          },
          false
        );


        if (returncodePromo && returncodePromo.data) {
          this.$toast.success("Code Promo  ajouté avec succès")
          this.init();
        }
      } else {

        this.$toast.error("Un problème est survenu")
        this.erreur = true;
      }
      /**/
    },

    async addReduction(){

      if (this.codeReduction.code == "" || this.codeReduction.reduction == 0 || this.codeReduction.usageLimit == 0) {

        this.$toast.error("Vérifiez vos entrées")

        return
        
      }

      const response = await apiRequest("POST","codepromo/add-param",this.codeReduction);
      //console.log("response",response);

      if(response && response.status == 200){
        this.$toast.success('Code de réduction ajouté avec succès');
        //console.log("we are done");
      }else{

        this.$toast.error('Un problème est survenu');

      }

    },
    async editReduction(){

      if (this.codeReduction.code == "" && this.codeReduction.reduction == 0 && this.codeReduction.usageLimit == 0) {

        this.$toast.error("Tous les champs sont vides");

        return
        
      }
      

      const response = await apiRequest("PUT","codepromo/update-param",this.codeReduction);

      if(response && response.status == 200){
        this.$toast.success('Code de réduction modifié avec succès');
        this.editRModal = false;
        this.getReduction();
        //console.log("we are done");
      }else{

        this.$toast.error('Un problème est survenu');

      }

    },
    async submitcodePromoFormModif() {

      let formData = new FormData();
      formData.append("id", this.codePromoFormModif.id);
      formData.append("titre", this.codePromoFormModif.titre);
      formData.append("sousTitre", this.codePromoFormModif.soustitre);
      formData.append("codePromoLink", this.codePromoFormModif.url_codePromo);
      formData.append("file", this.files);
      formData.append("visible", this.codePromoFormModif.visible);

      const returncodePromo = await apiRequest(
        "PUT",
        "codePromo",
        formData,
        true
      );


      if (returncodePromo && returncodePromo.data) {

        this.$toast.success("Code Promo  modifié avec succès")
        this.init();
      } else {
        this.$toast.error("Un problème est survenu")
      }
    },
    async desactivepromo() {
      this.showdisable = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );


      if (returncodePromo && returncodePromo.data) {
        this.$toast.success("Code Promo  désactivé avec succès")
        this.init();
      }
    },
    async activepromo() {
      this.showactive = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: true },
        false
      );


      if (returncodePromo && returncodePromo.data) {
        this.$toast.success("Code Promo  activé  avec succès")
        this.init();
      }
    },
    async deletepromo() {

      this.showDelete = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codepromo/action",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.$toast.success("Code Promo  supprimé avec succès")
        this.init();
      }
    },
    onFiltered(filteredItems) {

      this.filteredData = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;

      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      this.$v.$touch();
    },



    async actifpage(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "codepromo/find?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const codePromoFormatTable = listeWallet.data.promo.map((promo) => {
          return {
            id: promo.id,
            code: promo.code,
            reduction: promo.reduction + " %",
            dateCreation: new Date(promo.dateCreation).toLocaleString("fr-FR"),
            dateLimite: new Date(promo.dateLimite).toLocaleString("fr-FR"),
            birthday: promo.birthday ? "Oui" : "Non",
            actif: promo.actif,
            idAdmin: promo.idAdmin,
            moyensTransport: promo.moyensTransport,
            typesDistance: promo.typesDistance,
            experience: promo.experience,
            admin: promo.admin.firstname + " " + promo.admin.lastname,
            user: promo.birthday ? promo.user.firstname + " " + promo.user.lastname : "",
          };
        });


        this.newDataPromo = codePromoFormatTable;
        this.userChargeLoader = false;

      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearch() {

      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "codepromo/find?search=" + this.recherche,
        undefined,


        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.promo.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            domicile: user.adresse,
            trajet: user.nombreTrajet,
            reservation: user.nombreReser,
          };
        });
        this.newDataDomnewDataPromoicile = formattedTable;

        this.userChargeLoader = false;
      }
    },




    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;

      this.actifpage(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;

      this.actifpage(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;

      this.actifpage(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;

      this.actifpage(this.startValue, this.endValue);
    },





  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">


          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                    <a class="font-weight-bold active">Ajouter un code promo</a>
                  </template>
                <form @submit.prevent="submitcodePromoForm" class="row mt-3">
              <div class="col-md-6 ">
                <div class="form-group">
                  <label for="Code">Code (5 caractères max)</label>
                  <input type="text" id="Code" class="form-control" placeholder="Code" v-model="codePromoForm.code"
                    maxlength="5" @focus="showMessage = true" @blur="showMessage = false" />
                    <span v-if="showMessage" class="text-info">5 caractère au maximum</span>
                </div>
              

                <div class="form-group">
                  <label for="usage-limit">Usage Limite*</label>
                  <b-form-input id="usage-limit" type="number" v-model="codePromoForm.usageLimit"></b-form-input>
                </div>
                <div class="form-group">
                  <label for="">Réduction*</label>
                  <input type="number" id="" class="form-control" placeholder="Réduction"
                    v-model="codePromoForm.reduction" />
                </div>
                <div class="form-group">
                  <label for="">Date Limite*</label>
                  <b-form-input id="date-time" type="date" v-model="codePromoForm.dateLimite"></b-form-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Moyen de transport*</label>
                  <multiselect v-model="codePromoForm.moyensTransport" :options="moyensTransport" :multiple="true"
                    :close-on-select="true"></multiselect>
                </div>
                <div class="form-group">
                  <label for="">Type de distance*</label>
                  <multiselect v-model="codePromoForm.typesDistance" :options="typesDistance" :multiple="true"
                    :close-on-select="true"></multiselect>
                </div>
                <div class="form-group">
                  <label for="">Expérience*</label>
                  <multiselect v-model="codePromoForm.experience" :options="experience" :multiple="true"
                    :close-on-select="true"></multiselect>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary float-right">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                    <a class="font-weight-bold active">Ajouter une reduction de Bienvenu</a>
                  </template>
                <form @submit.prevent="addReduction" class="row mt-3">
              <div class="col-md-6 ">
                <div class="form-group">
                  <label for="Coder">Code</label>
                  <input type="text" id="Coder" class="form-control" placeholder="Code" v-model="codeReduction.code"
                    @focus="showMessage = true" @blur="showMessage = false" />
                </div>
                <!-- <span v-if="showMessage" class="text-info">5 caractère au maximum</span> -->
                <div class="form-group">
                  <label for="reduction">Réduction*</label>
                  <input type="reduction" id="" class="form-control" placeholder="Réduction"
                    v-model="codeReduction.reduction" />
                </div>
                <div class="form-group">
                  <label for="usage-limit">Usage Limite*</label>
                  <b-form-input id="usage-limit" type="number" v-model="codeReduction.usageLimit"></b-form-input>
                </div>

                <div class="form-group">
                  <button class="btn btn-primary">
                    Ajouter
                  </button>
                </div>
              </div>
              
            </form>
              </b-tab>
            </b-tabs>


            
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="codePromo.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">


            <div class="row d-flex justify-content-between  p-2">
              <div class="card-title">
                <h5>Gérer</h5>
              </div>

              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des codes promo</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="newDataPromo.length != 0 ? newDataPromo : codePromo"
                      :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <!--<a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>-->
                        <a href="javascript:void(0);" class="text-danger" @click="deleteRow(row.item)" v-b-tooltip.hover
                          title="supprimer">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                      <transition mode="out-in">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                data-toggle="tooltip" data-placement="bottom" title="Prem">
                                <ion-icon name="play-skip-back-outline"></ion-icon>
                              </button>
                              <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                                data-toggle="tooltip" data-placement="bottom" title="Préc">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                data-placement="bottom" title="Suiv" :disabled="endValue >= totalValue">
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                <ion-icon name="play-skip-forward-outline"></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </b-tab>
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des réductions de Bienvenus</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="keyToFind"
                          @input="findReduction()" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="foundReduction.length >0 ? foundReduction : reductionTable"
                      :fields="fieldsR" responsive="sm" :per-page="perPageR" :current-page="currentPageR"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(action)="row">
                        <!--<a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>-->
                        <a href="javascript:void(0);" class="text-danger" @click="handleRedEdit(row.item)" v-b-tooltip.hover
                          title="supprimer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                      <transition mode="out-in">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                data-toggle="tooltip" data-placement="bottom" title="Prem">
                                <ion-icon name="play-skip-back-outline"></ion-icon>
                              </button>
                              <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                                data-toggle="tooltip" data-placement="bottom" title="Préc">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                data-placement="bottom" title="Suiv" :disabled="endValue >= totalValue">
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                <ion-icon name="play-skip-forward-outline"></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="erreur" title="Veuillez remplir toutes les options."
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false">Ok</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment supprimer ce code promo ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showdisable" title="Voulez-vous vraiment désactiver ce bon promo ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivepromo">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showactive" title="Voulez-vous vraiment activer bon promo ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activepromo">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-center" centered size="xl" v-model="showEdit" title="Editer utilisateur" title-class="font-18"
      hide-footer>
      <form enctype="multipart/form-data" @submit.prevent="submitcodePromoFormModif">
        <div class="form-group">
          <label for="tite">Titre</label>
          <input type="text" id="title" class="form-control" placeholder="titre" v-model="codePromoFormModif.titre" />
        </div>
        <div class="form-group">
          <label for="tite">Sous titre</label>
          />
        </div>
        <div class="form-group">
          <label for="tite">texte</label>
          <input type="text" id="title" class="form-control" placeholder="sous titre"
            v-model="codePromoFormModif.soustitre" />
        </div>
        <div class="form-group">
          <label for="tite">Lien du bon promo</label>
          <input type="url" id="title" class="form-control" placeholder="lien du bon promo"
            v-model="codePromoFormModif.url_codePromo" />
        </div>
        <div class="form-group">
          <label for="title">Image</label>
          <input type="file" id="files" ref="files" v-on:change="handleFileUploadModif" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
          placeholder="titre" />
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-center" centered size="lg" v-model="editRModal" title="Editer la réduction" title-class="font-18"
      hide-footer>
      <form enctype="multipart/form-data" @submit.prevent="editReduction">
        <div class="col-md-12 ">
                <div class="form-group">
                  <label for="Coder">Code (Personnalisé)</label>
                  <input type="text" id="Coder" class="form-control" placeholder="Code" v-model="codeReduction.code"
                    @focus="showMessage = true" @blur="showMessage = false" />
                </div>
                <!-- <span v-if="showMessage" class="text-info">5 caractère au maximum</span> -->
                <div class="form-group">
                  <label for="reduction">Réduction*</label>
                  <input type="reduction" id="" class="form-control" placeholder="Réduction"
                    v-model="codeReduction.reduction" />
                </div>
                <div class="form-group">
                  <label for="usage-limit">Usage Limite*</label>
                  <b-form-input id="usage-limit" type="number" v-model="codeReduction.usageLimit"></b-form-input>
                </div>

                <div class="form-group">
                  <button class="btn btn-primary">
                    Modifier
                  </button>
                </div>
              </div>
      </form>
    </b-modal>
  </Layout>
</template>


<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
